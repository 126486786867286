import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useAppSelector } from '../../../../core/configStore';
import { selectCurrentUser } from '../../../auth/selectors';
import { selectCompany } from '../../../company/selectors';

const useDatadog = (): void => {
  const user = useAppSelector(selectCurrentUser);
  const company = useAppSelector(selectCompany);
  const datadogLogsEnabled = process.env.DATADOG_LOGS_ENABLED === 'true';
  const datadogRumEnabled = process.env.DATADOG_RUM_ENABLED === 'true';

  React.useEffect(() => {
    if (datadogLogsEnabled) {
      datadogLogs.init({
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: process.env.DATADOG_SERVICE,
        env: process.env.DATADOG_ENV,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
        sampleRate: Number(process.env.DATADOG_SAMPLING_RATE),
        silentMultipleInit: true
      });
    }
  }, [datadogLogsEnabled]);

  React.useEffect(() => {
    if (!!user && !!company && datadogRumEnabled) {
      datadogRum.init({
        applicationId: process.env.DATADOG_APPLICATION_ID,
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: process.env.DATADOG_SERVICE,
        env: process.env.DATADOG_ENV,
        sampleRate: Number(process.env.DATADOG_SAMPLING_RATE),
        trackInteractions: true,
        defaultPrivacyLevel: 'allow',
        silentMultipleInit: true
      });

      datadogRum.setUser({
        id: user.id,
        name: `${user.firstname} ${user.lastname}`,
        email: user.email,
        companyId: company.id,
        companyName: company.name
      });

      datadogRum.startSessionReplayRecording();
    }
  }, [
    user?.id,
    user?.firstname,
    user?.lastname,
    user?.email,
    company?.id,
    company?.name,
    datadogRumEnabled
  ]);
};

export default useDatadog;
